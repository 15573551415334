import React from 'react';
import { Column, Columns, Container } from 'bloomer';
import { ContTYC } from './styles';
import { TitleSection, TitleSectionH1, TitleSectionSecondary } from '../../styles/GlobalStyles';

export const Privacidad = () => {

  return (
    <ContTYC>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 8 }}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionH1 className={'animate__animated animate__fadeInUp'}>AVISO DE <span>PRIVACIDAD</span></TitleSectionH1>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>Declaración de privacidad</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                En <a>callmatik.com</a> valoramos tu privacidad cuando utilizas nuestros servicios siempre trabajamos para garantizarla.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>Por qué se recopilan tus datos</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                "Para CallMatik tú eres lo más importante". Para cumplirlo, necesitamos saber más sobre ti, pero siempre respetando tu privacidad.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Para llevar a cabo tu contrato, utilizamos los datos que nos proporcionas al regístrate en la web , cuando utilizas el servicio o te pones en contacto con nosotros. Estos datos pueden ser tu nombre, dirección de correo electrónico, dirección, número de teléfono y similares.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Podemos utilizar tus datos personales para mejorar nuestros servicios y aumentar la calidad de nuestra plataforma, así como para fines de marketing que pueden incluir el análisis y la segmentación en perfiles.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Solo conservamos tus datos durante el tiempo que sea necesario para prestar nuestros servicios, a menos que estemos legalmente obligados a conservar los datos durante un período más prolongado.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Para procesar tu compra es posible que necesitemos compartir tus datos personales con terceros, como agencias de pago y otras empresas. Ten la seguridad de que nunca venderemos tus datos a nadie.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>Cookies</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Nuestra plataforma utiliza cookies funcionales, analíticas y de seguimiento. Necesitamos cookies funcionales para que la plataforma funcione correctamente y complete tus compras.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>Tus derechos</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Si no deseas recibir correos electrónicos u otras comunicaciones de nuestra parte, siempre puedes cancelar la suscripción. Si eliges cancelar la suscripción, puedes hacerlo a través del correo de atención al cliente <a>hola@callmatik.com</a>
                Tienes el derecho legal de pedirnos que inspeccionemos, rectifiquemos o borremos todos los datos personales que hayamos almacenado sobre ti.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>Cambios</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Podemos revisar o enmendar esta declaración para reflejar cambios en nuestro negocio o cambios en la ley.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionSecondary>¿Dudas?</TitleSectionSecondary>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Si tienes cualquier pregunta, ponte en contacto con nuestro departamento de atención al cliente <a>hola@callmatik.com</a>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Container>
    </ContTYC >
  )
}

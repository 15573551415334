import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {Privacidad} from '../components/Privacidad';

const PrivacidadPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/privacidad"
      />

      <Privacidad/>

    </Layout>
  )
}

export default PrivacidadPage
